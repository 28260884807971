/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ApiUrl } from "../../../api/api-url";

//--- Action
import * as postsAction from "../../../redux/store/posts/posts.store";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import * as viVN from "../../../language/vi-VN.json";

//--- Material Control
import {
  DialogActions,
  Button,
  TextField,
  DialogContent,
  DialogTitle,
  Dialog,
  makeStyles,
  Typography,
  IconButton,
  Select,
  MenuItem,
} from "@material-ui/core";

//--- Material Icon
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";

//--- Notifications
import ShowNotification from "../../../components/react-notifications/react-notifications";
import {
  NotificationMessageType,
  APIUrlDefault,
  MaxSizeImageUpload,
} from "../../../utils/configuration";
import * as appActions from "../../../core/app.store";
import * as proviceAction from "../../../redux/store/province-management/province.store";
import * as districtAction from "../../../redux/store/district-management/district.store";
//--- Styles
import "../posts.scss";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

function EditPosts(props) {
  const classes = useStyles();

  const {
    isOpen,
    onClose,
    onSuccess,
    GetListAll,
    postsModel,
  } = props;


  const [content, setContent] = useState();

  const urlUploadImage = APIUrlDefault + ApiUrl.UrlUploadFromEditor;
  const [proviceSelect, setProviceSelect] = useState();
  const [districtByProvinceId, setDistrictByProvinceId] = useState();
  const [provinceId, setProvinceId] = useState(postsModel?.provinceId);
  const [districtId, setDistrictId] = useState(postsModel?.districtId);
  const [statusAction, setStatusAction] = useState(postsModel?.statusAction);
  useEffect(() => {
    if (postsModel) {
      setValue("provindeId", postsModel?.provindeId);
      setProvinceId(postsModel?.provinceId);
      setValue("districtId", postsModel.districtId);
      setDistrictId(postsModel.districtId);
      setStatusAction(postsModel.statusAction)
    }
    Promise.all([GetLookupProvince()])
      .then((res) => {
        const [provinceLookupModels] = res;
        setProviceSelect(
          provinceLookupModels && provinceLookupModels.content
            ? provinceLookupModels.content
            : []
        );
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    if (!provinceId) return;
    GetDistrictByProvinceId(provinceId);
  }, [provinceId || postsModel?.provinceId]);

  const GetLookupProvince = () => {
    return new Promise((resolve, reject) => {
      proviceAction.GetLookupProvince().then(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
          err &&
            err.errorType &&
            ShowNotification(
              viVN.Errors[err.errorType],
              NotificationMessageType.Error
            );
        }
      );
    });
  };

  const GetDistrictByProvinceId = (provinceId) => {
    if (!provinceId) return;
    return districtAction
      .GetDistrictByProvinceId(provinceId)
      .then((res) => {
        setDistrictByProvinceId(res && res.content ? res.content : []);
      })
      .catch((err) => {});
  };
  const { register, handleSubmit, setError, errors, clearErrors, setValue } =
    useForm({
      mode: "all",
      reValidateMode: "onBlur",
    });

  const onSubmit = (data) => {
    if (!data) {
      return;
    }
    let formData = new FormData();
    data.title && formData.append("title", data.title);
    data.address && formData.append("address", data.address);
    statusAction && formData.append("statusAction", statusAction);
    postsModel.id && formData.append("id", postsModel.id);
    provinceId && formData.append("provinceId", provinceId);
    districtId && formData.append("districtId", districtId);
    formData.append("content", content);
    postsAction
      .UpdateReflect(formData)
      .then((result) => {
        if (result) {
          GetListAll();
          onClose();
        }
      })
      .catch((err) => {
        onSuccess();
        ShowNotification(
          viVN.Errors[err.errorType],
          NotificationMessageType.Error
        );
      });
  };

  const handleChangeContent = (editorContent) => {
    clearErrors(["editorContent"]);
    if (editorContent === "<p><br></p>") {
      setError("editorContent", { type: "required" });
      setContent("");
    } else {
      clearErrors("editorContent");
      setContent(editorContent);
    }
  };


  const handleChangeSelect = (event) => {
    setValue("provindeId", event.target.value);
    setProvinceId(event.target.value);
  };

  const handleChooseDistrict = (event) => {
    setValue("districtId", event.target.value);
    setDistrictId(event.target.value);
  };

  return (
    <div>
      <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth="md">
        <DialogTitle disableTypography className="border-bottom">
          <Typography variant="h6">Chỉnh sửa phản ánh hiện trường</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          {postsModel && (
            <DialogContent className="pt-4 pb-2">
              <div className="form-group">
                <label className="text-dark">
                  Tiêu đề<span className="required"></span>
                </label>
                <TextField
                  name="title"
                  
                  fullWidth
                  type="text"
                  className="form-control"
                  inputProps={{ maxLength: 150 }}
                  defaultValue={postsModel.title}
                  onChange={(e) => setValue("title", e.target.value)}
                  inputRef={register({ required: true, maxLength: 150 })}
                  error={errors.title && errors.title.type === "required"}
                />
                {errors.title && errors.title.type === "required" && (
                  <span className="error">Trường này là bắt buộc</span>
                )}
                {errors.title && errors.title.type === "maxLength" && (
                  <span className="error">Trường này không quá 150 ký tự</span>
                )}
              </div>
              <div className="form-group">
                <label className="text-dark">Nội dung</label>
                <SunEditor
                  enableToolbar={true}
                  showToolbar={true}
                  setContents={postsModel.content}
                  videoFileInput={false}
                  setOptions={{
                    height: 500,
                    imageUploadUrl: urlUploadImage,
                    imageUploadSizeLimit: MaxSizeImageUpload,
                    imageAccept: ".jpg,.jpeg,.png,.gift,.svg,.tif",
                    buttonList: [
                      [
                        "undo",
                        "redo",
                        "font",
                        "fontSize",
                        "formatBlock",
                        "paragraphStyle",
                        "blockquote",
                        "bold",
                        "underline",
                        "italic",
                        "strike",
                        "subscript",
                        "superscript",
                        "fontColor",
                        "hiliteColor",
                        "textStyle",
                        "removeFormat",
                        "outdent",
                        "indent",
                        "align",
                        "horizontalRule",
                        "list",
                        "lineHeight",
                        "table",
                        "link",
                        "image",
                        "video",
                        "audio",
                        "fullScreen",
                        "showBlocks",
                        "codeView",
                      ],
                    ],
                  }}
                  onChange={handleChangeContent}
                  onBlur={(event, editorContents) =>
                    handleChangeContent(editorContents)
                  }
                />
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-6">
                    <label className="text-dark">Tỉnh/ thành phố</label>
                    <Select
                      fullWidth
                      name="provinceId"
                      defaultValue={provinceId}
                      onChange={handleChangeSelect}
                    >
                      {proviceSelect && proviceSelect.length > 0 ? (
                        proviceSelect.map((item, index) => (
                          <MenuItem key={index} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value="">Không có tỉnh thành nào</MenuItem>
                      )}
                    </Select>
                    <TextField
                      type="text"
                      name="provindeId"
                      className="w-100"
                      inputRef={register({ required: false })}
                      hidden
                    />
                  </div>
                  <div className="col-12 col-md-6 col-lg-6">
                    <label className="text-dark">Quận - huyện</label>
                    <Select
                      disabled={provinceId ? false : false}
                      fullWidth
                      defaultValue={districtId}
                      onChange={handleChooseDistrict}
                    >
                      {districtByProvinceId &&
                      districtByProvinceId.length > 0 ? (
                        districtByProvinceId.map((item) => (
                          <MenuItem value={item.id}>{item.name}</MenuItem>
                        ))
                      ) : (
                        <MenuItem value="">Không có quận huyện nào</MenuItem>
                      )}
                    </Select>
                    <TextField
                      type="text"
                      name="districtId"
                      className="w-100"
                      inputRef={register({ required: false })}
                      hidden
                    />
                  </div>
                </div>
                <div className="form-group mt-2">
                  <label className="text-dark">Địa chỉ</label>
                  <TextField
                    name="address"
                    fullWidth
                    type="text"
                    className="form-control"
                    defaultValue={postsModel.address}
                    inputRef={register({ required: false })}
                    onChange={(e) => setValue("address", e.target.value)}
                  />
                </div>
                <div className="form-group mt-2">
                  <label className="text-dark">Trạng thái</label>
                  <Select
                    name="statusAction"
                    variant="standard"
                    style={{ border: 0}}
                    className="form-control"
                    placeholder="Trạng thái"
                    defaultValue={postsModel.statusAction}
                    onChange={(e) => setStatusAction(e.target.value)}
                  >
                    <MenuItem value={""}>----------------</MenuItem>
                    <MenuItem value={1}>Đã xử lý</MenuItem>
                    <MenuItem value={0}>Chưa xử lý</MenuItem>
                    <MenuItem value={2}>Đang xử lý</MenuItem>
                  </Select>
                </div>
              </div>
            </DialogContent>
          )}

          <DialogActions className="border-top">
            <Button
              type="button"
              onClick={onClose}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              startIcon={<SaveIcon />}
            >
              Lưu
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(EditPosts);
