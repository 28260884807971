/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import viLocale from "date-fns/locale/vi";
import dateformat from "dateformat";

import * as consultTheCommunityAction from "./../../../../../redux/store/consult-the-community/consult-the-community.store";
import * as opinionFormAction from "./../../../../../redux/store/opinion-form/opinion-form.store";
import * as appActions from "./../../../../../core/app.store";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Checkbox,
  makeStyles,
  Typography,
  IconButton,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";

import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";
import VisibilityIcon from "@material-ui/icons/Visibility";

import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

// import FormTemplateComponent from "../form-template/form-template.view";
import FileManagement from "./../../../../../components/file_management/file_management";

import * as viVN from "./../../../../../language/vi-VN.json";
import { NotificationMessageType, MaxSizeImageUpload, } from "./../../../../../utils/configuration";
import ShowNotification from "./../../../../../components/react-notifications/react-notifications";

import "./edit-dialog-comment.scss";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  accordionSummary: {
    minHeight: "50px !important",
    borderBottom: "1px solid #e3e6f0",
    height: 50,
    paddingLeft: 0,
  },
  accordionHeading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightBold,
    marginTop: 2,
  },
}));

function EditConsultTheCommunity(props) {
  const classes = useStyles();
  const { showLoading, isOpen, onClose, onSuccess, consultCommunityId } = props;

  //--- Variables
  const [content, setContent] = useState();
  const [statusId, setStatusId] = useState(null);
  const [hotNew, setHotNew] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [consultCommunityModel, setConsultCommunityModel] = useState(null);
  const [statusLookUpModel, setStatusLookUpModel] = useState(null);
  const [formTemplateLookUpModel, setFormTemplateLookUpModel] = useState([]);
  const [isShowFormTemplate, setShowFormTemplate] = useState(false);
  const [formTemplateModel, setFormTemplateModel] = useState(null);
  const [formTemplateStringModel, setFormTemplateStringModel] = useState("");

  const [files, setFiles] = useState([]);
  const [filesTemp, setFilesTemp] = useState([]);
  const [isShow, setShow] = useState(false);

  const {
    register,
    handleSubmit,
    errors,
    setError,
    clearErrors,
    setValue,
  } = useForm({ mode: "all", reValidateMode: "onBlur" });


  //--- Save data
  const onSaveData = (data, formTemplate) => {
    showLoading(true);

    let params = {
      id: consultCommunityModel.id,
      title: data.title,
      startTime: startDate,
      endTime: endDate,
      isHotNew: hotNew,
      statusId: statusId,
      order: Number(data.order),
      content: content,
      formOpinion: null,
      planningId: consultCommunityModel.planningId,
      formTemplateStringModel: formTemplate,
    };

    if (files && files.length > 0) {
      params = {
        ...params,
        documentUploadId: files[0].fileId,
      };
    }
    consultTheCommunityAction.UpdateConsultCommunity(params).then(
      (res) => {
        showLoading(false);
        onSuccess();
      },
      (err) => {
        showLoading(false);
        ShowNotification(
          viVN.Errors[(err && err.errorType) || "UnableHandleException"],
          NotificationMessageType.Error
        );
      }
    );
  };

  const onEditSubmit = (data) => {
    if (!data) {
      return;
    }

    if (!formTemplateStringModel) {
      showLoading(true);
      opinionFormAction
        .GetDetailFormTemplate(consultCommunityModel.formTemplateId)
        .then(
          (res) => {
            showLoading(false);
            if (res && res.content) {
              onSaveData(data, JSON.stringify(res.content));
            } else {
              ShowNotification(
                viVN.Errors[(res && res.errorType) || "UnableHandleException"],
                NotificationMessageType.Error
              );
            }
          },
          (err) => {
            showLoading(false);
            ShowNotification(
              viVN.Errors[(err && err.errorType) || "UnableHandleException"],
              NotificationMessageType.Error
            );
          }
        );
    } else {
      onSaveData(data, formTemplateStringModel);
    }
  };


  const onCloseSelectFile = () => {
    setShow(false);
    setFiles(filesTemp);
  };

  const onSaveSelectFile = () => {
    setShow(false);
  };

  return (
    <div>
      <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth="md">
        <DialogTitle disableTypography className="border-bottom">
          <Typography variant="h6">Sửa ý kiến</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <form onSubmit={handleSubmit(onEditSubmit)} autoComplete="off">
        <DialogContent className="pt-4 pb-2" dividers>
          <div className="form-group">
            <label className="text-dark">
              Nội dung bình luận
            </label>
            <textarea
              name="brief"
              value={content}
              onChange={(e) => setContent(e.target.value)}
              rows="5"
              ref={register({ required: false })}
              className="form-control"
            ></textarea>
          </div>
        </DialogContent>
          <DialogActions className="border-top">
            <Button
              onClick={onClose}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              startIcon={<SaveIcon />}
            >
              Lưu
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {isShow && (
        <Dialog
          onClose={onCloseSelectFile}
          open={isShow}
          fullWidth={true}
          maxWidth="md"
          className="dialog-preview-form"
        >
          <DialogTitle disableTypography>
            <Typography variant="h6">Quản lý file</Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onCloseSelectFile}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <FileManagement
              files={files}
              setFiles={setFiles}
              acceptedFiles={["doc", "docx", "txt", "pdf"]}
            />
          </DialogContent>

          <DialogActions>
            <Button
              type="button"
              onClick={onCloseSelectFile}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            {files && files.length > 0 && (
              <Button
                type="button"
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={onSaveSelectFile}
              >
                Lưu
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}

      {/* {isShowFormTemplate && (
        <FormTemplateComponent
          isShow={isShowFormTemplate}
          setShow={handleClickCloseFormTemplate}
          formTemplateId={consultCommunityModel.formTemplateId}
          formTemplateModel={formTemplateModel}
          setFormTemplateModel={setFormTemplateModel}
          setFormTemplateStringModel={setFormTemplateStringModel}
          isShowActionAddEdit={false}
        />
      )} */}
    </div>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditConsultTheCommunity);
