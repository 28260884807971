import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as consultTheCommunityAction from "./../../../../redux/store/consult-the-community/consult-the-community.store";
import * as viVN from "./../../../../language/vi-VN.json";
import {
  NotificationMessageType,
  Transition,
} from "./../../../../utils/configuration";

//--- Material Control
import {
  Button,
  Tooltip,
  Fab,
  Dialog,
  AppBar,
  Toolbar,
  Typography,
} from "@material-ui/core";

//--- Material Icon
import AddCircle from "@material-ui/icons/AddCircle";
import CloseIcon from "@material-ui/icons/Close";
import RefreshIcon from "@material-ui/icons/Refresh";

//--- Component
import ListDialogComment from "./list-dialog-comment/list-dialog-comment.view";
import AddDialogComment from "./add-dialog-comment/add-dialog-comment.view";
import EditDialogComment from "./edit-dialog-comment/edit-dialog-comment.view";
import DeleteDialog from "../../../../components/dialog-delete/dialog-delete.view";
import ShowNotification from "./../../../../components/react-notifications/react-notifications";

function DialogComment(props) {
  const {
    showDialogComment,
    dataComment,
    setDataComment,
    classes,
    planningId,
    planningName,
    showConsult,
    setShowDialogComment,
    settings,
    reflectPostId,
  } = props;

  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const [item, setItem] = useState({});
  const [consultCommunityId, setConsultCommunityId] = useState(null);
  const [formTemplateId, setFormTemplateId] = useState(null);
  const [data, setData] = useState({
    planning: null,
    title: "",
    status: null,
    hotNew: null,
    startTime: null,
    endTime: null,
  });

  useEffect(() => {
    setClientSetting(settings);
  }, [settings]);

  const [clientSetting, setClientSetting] = useState();

  //--- Add Dialog
  const handleOpenAddDialog = () => {
    setOpenAddDialog(true);
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
    setItem();
  };

  const onSuccessAdd = () => {
    handleCloseAddDialog();
    ShowNotification(
      viVN.Success.CreateSuccess,
      NotificationMessageType.Success
    );
  };

  //--- Edit Dialog
  const handleOpenEditDialog = (item) => {
    if (item) {
      setItem(item);
      setOpenAddDialog(true);
    }
  };

  const handleCloseEditDialog = () => {
    setConsultCommunityId(null);
    setOpenEditDialog(false);
  };

  const onSuccessEdit = () => {
    handleCloseEditDialog();
    ShowNotification(
      viVN.Success.UpdateSuccess,
      NotificationMessageType.Success
    );
  };

  //--- Delete Dialog
  const handleOpenDeleteDialog = (id, formTemplateId) => {
    if (id && formTemplateId) {
      setConsultCommunityId(id);
      setFormTemplateId(formTemplateId);
      setOpenDeleteDialog(true);
    }
  };

  const handleCloseDeleteDialog = () => {
    setConsultCommunityId(null);
    setFormTemplateId(null);
    setOpenDeleteDialog(false);
  };

  const handleDelete = () => {
    consultCommunityId &&
      formTemplateId &&
      consultTheCommunityAction
        .DeleteConsultCommunity(consultCommunityId, formTemplateId)
        .then(
          (res) => {
            handleCloseDeleteDialog();
            ShowNotification(
              viVN.Success.DeleteSuccess,
              NotificationMessageType.Success
            );
          },
          (err) => {
            handleCloseDeleteDialog();
            err &&
              err.errorType &&
              ShowNotification(
                viVN.Errors[err.errorType],
                NotificationMessageType.Error
              );
          }
        );
  };
  const onClickReflectActive = (id) => {
    // postsAction
    // .ReflectActive(id)
    // .then(
    //   (res) => {
    //     if (res && res.content) {
          
    //     }
    //   },
    //   (err) => {
    //     err &&
    //       err.errorType &&
    //       ShowNotification(
    //         viVN.Errors[err.errorType],
    //         NotificationMessageType.Error
    //       );
    //   }
    // );
  }
  const onClickReflectDeActive = (id) => {
    // postsAction
    // .ReflectDeActive(id)
    // .then(
    //   (res) => {
    //     if (res && res.content) {
         
    //     }
    //   },
    //   (err) => {
    //     err &&
    //       err.errorType &&
    //       ShowNotification(
    //         viVN.Errors[err.errorType],
    //         NotificationMessageType.Error
    //       );
    //   }
    // );
  }
  return (
    <Dialog
      fullScreen
      open={showDialogComment}
      onClose={() =>setShowDialogComment(false)}
      TransitionComponent={Transition}
    >
      {clientSetting && (
        <AppBar
          className={classes.appBar}
          style={{ background: clientSetting.color }}
        >
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              Danh sách bình luận
            </Typography>
            <Button
              color="inherit"
              onClick={() =>setShowDialogComment(false)}
            >
              <CloseIcon />
            </Button>
          </Toolbar>
        </AppBar>
      )}

      <div className="p-4">
        <div className="consult-the-community">
          <div className="d-sm-flex align-items-center justify-content-end mb-3">
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenAddDialog}
              startIcon={<AddCircle />}
            >
              Thêm bình luận
            </Button>
          </div>

          <ListDialogComment
            dataList={dataComment}
            editAction={handleOpenEditDialog}
            deleteAction={handleOpenDeleteDialog}
          />

          {openAddDialog && (
            <AddDialogComment
              isOpen={openAddDialog}
              dataComment={dataComment}
              postsModels={props.postsModels}
              reflectPostId={reflectPostId}
              setDataComment={(a)=>setDataComment(a)}
              setPostsModels={(a) => props.setPostsModels(a)}
              onClose={handleCloseAddDialog}
              onSuccess={onSuccessAdd}
              planningId={planningId}
              item={item}
            />
          )}

          {/* {openEditDialog && (
            <EditDialogComment
              isOpen={openEditDialog}
              consultCommunityId={consultCommunityId}
              onClose={handleCloseEditDialog}
              onSuccess={onSuccessEdit}
            />
          )}

          {openDeleteDialog && (
            <DeleteDialog
              isOpen={openDeleteDialog}
              onClose={handleCloseDeleteDialog}
              onSuccess={handleDelete}
              header={"Xóa ý kiến"}
              content={"Bạn có chắc chắn muốn xóa?"}
            />
          )} */}
        </div>
      </div>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  settings: state.clientSetting.clientSetting,
});

export default connect(mapStateToProps)(DialogComment);
