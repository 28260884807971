import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as appActions from "../../../core/app.store";
import * as InitMapStore from '../../../redux/store/init-map/init-map.store';


function ShowHideMap(props) {
    const { is_active, mapSetting } = props;
    const setActiveMap = (_is_active = is_active) => {
        props.UpdateMapSetting({ ...mapSetting, is_active: !_is_active })
    }

    return (

        is_active ? (<Button color="inherit" onClick={() => setActiveMap()}>
            Ẩn bản đồ
        </Button>) : (<Button color="inherit" onClick={() => setActiveMap()}>
            Hiện bản đồ
        </Button>)

    )
}

const mapStateToProps = state => ({
    initMap: state.initMap
})

const mapDispatchToProps = dispatch => bindActionCreators({
    showLoading: appActions.ShowLoading,
    UpdateMapSetting: InitMapStore.UpdateMapSetting
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ShowHideMap)