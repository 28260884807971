import React from "react";
import { Router, Switch } from "react-router-dom";
import RouteComponent from "./route-config.jsx";
import { UrlCollection } from "./common/url-collection";
import history from "./common/history";

//--- Loading
import AppLoading from "./components/loading/loading.view";

//--- Layout
import LayoutView from "./components/layout/layout.view";
import LayoutUserView from "./components/layout/layout-user.view.jsx";

//--- Home
import Home from "./modules/home/home.view.jsx";

//--- Admin
import Login from "./modules/login/login.view.jsx";
import ForgotPassword from "./modules/forgot-password/forgot-password.view.jsx";
import ResetPassword from "./modules/reset-password/reset-password.view.jsx";

//--- Consult the community
import EmailTemplate from "./modules/email-template/email-template";
import RoleManagement from "./modules/role-management/role-management";
import ContactManagement from "./modules/contact-management/contact-management.view";
import EmailGenerated from "./modules/email-generated/email-generated.view";
import UserManagement from "./modules/user-management/user-management.view";
import MemberManagement from "./modules/member-management/member-management.view";
import CommuneManagement from "./modules/communce-management/communce-management.view";
import ProviceManagement from "./modules/province-management/provice-management.view";
import DistrictManagement from "./modules/district-management/district-management.view";
import UserLogHistory from "./modules/user-log/user-log-history.view";
import LandTypeManagement from "./modules/land-type-management/land-type-management.view";
import LandTypeDetailManagement from "./modules/land-type-detail-management/land-type-detail-management.view";

//--- Slider
import Slider from "./modules/slider/slider.view.jsx";

//--- News
import News from "./modules/news/news.view.jsx";

//--- Map
import MapData from "./modules/map-data/map-data.view.jsx";
import InitMapDataView from "./modules/init-map-data/init-map-data";

//--- Records management
import RecordsManagement from "./modules/planning-announcement-process/records-management/records-management.view.jsx";

//--- Link
import LinkGroupManagementView from "./modules/link-group/link-group-management.view.jsx";
import ServiceLinkManagementView from "./modules/service-links/service-link-management.view";

//--- Opinion form
import OpinionForm from "./modules/opinion-form/opinion-form.view.jsx";

//--- Table Layer Structure
import TableStructure from "./modules/table-structure/table-structure.view.jsx";

//--- Access denied
import AccessDenied from "./modules/access-denied/access-denied.view.jsx";

import DocumentManagement from "./modules/document-management/document-management.view.jsx";
import Posts from "./modules/posts/posts.view.jsx";
import MyAccount from "./modules/my-account/my-account.view.jsx";

function App() {
  return (
    <div>
      <AppLoading />
      <Router history={history}>
        <Switch>
          <RouteComponent
            exact
            layout={LayoutUserView}
            component={Login}
            path={UrlCollection.Login}
          />
          <RouteComponent
            exact
            layout={LayoutUserView}
            component={ForgotPassword}
            path={UrlCollection.ForgotPassword}
          />
          <RouteComponent
            exact
            layout={LayoutUserView}
            component={ResetPassword}
            path={UrlCollection.ResetPassword}
          />

          <RouteComponent
            exact
            layout={LayoutView}
            component={RecordsManagement}
            path={UrlCollection.Home}
          />

          {/* Slider */}
          <RouteComponent
            exact
            layout={LayoutView}
            component={Slider}
            path={UrlCollection.Slider}
          />

          {/* News */}
          <RouteComponent
            exact
            layout={LayoutView}
            component={News}
            path={UrlCollection.News}
          />
          <RouteComponent
            exact
            layout={LayoutView}
            component={Posts}
            path={UrlCollection.Posts}
          />
          <RouteComponent
            exact
            layout={LayoutView}
            component={MemberManagement}
            path={UrlCollection.MemberManagement}
          />

          {/* Planning Announcement Process */}
          <RouteComponent
            exact
            layout={LayoutView}
            component={RecordsManagement}
            path={UrlCollection.PlanningDatabase + ":dbTypeId"}
          />

          {/* Map */}
          <RouteComponent
            exact
            layout={LayoutView}
            component={MapData}
            path={UrlCollection.MapData}
          />
          <RouteComponent
            exact
            layout={LayoutUserView}
            component={InitMapDataView}
            path={UrlCollection.CreateMapData}
          />

          {/* Email Template */}
          <RouteComponent
            exact
            layout={LayoutView}
            component={EmailTemplate}
            path={UrlCollection.EmailTemplate}
          />

          {/*Email Generated */}
          <RouteComponent
            exact
            layout={LayoutView}
            component={EmailGenerated}
            path={UrlCollection.EmailGenerated}
          />

          {/* Role Management */}
          <RouteComponent
            exact
            layout={LayoutView}
            component={RoleManagement}
            path={UrlCollection.RoleManagement}
          />

          {/* Contact Management */}
          <RouteComponent
            exact
            layout={LayoutView}
            component={ContactManagement}
            path={UrlCollection.ContactManagement}
          />

          {/* User Management */}
          <RouteComponent
            exact
            layout={LayoutView}
            component={UserManagement}
            path={UrlCollection.UserManagement}
          />

          {/* commune Management */}
          <RouteComponent
            exact
            layout={LayoutView}
            component={CommuneManagement}
            path={UrlCollection.CommuneManagement}
          />

          {/* District Management */}
          <RouteComponent
            exact
            layout={LayoutView}
            component={DistrictManagement}
            path={UrlCollection.DistrictManagement}
          />

          {/* Province Management */}
          <RouteComponent
            exact
            layout={LayoutView}
            component={ProviceManagement}
            path={UrlCollection.ProvinceManagement}
          />

          {/* Link Group Management */}
          <RouteComponent
            exact
            layout={LayoutView}
            component={LinkGroupManagementView}
            path={UrlCollection.LinkGroup}
          />

          {/* Service Link Management */}
          <RouteComponent
            exact
            layout={LayoutView}
            component={ServiceLinkManagementView}
            path={UrlCollection.ServiceLink}
          />
          {/* User log */}
          <RouteComponent
            exact
            layout={LayoutView}
            component={UserLogHistory}
            path={UrlCollection.UserLogHistory}
          />

          {/* Opinion Form */}
          <RouteComponent
            exact
            layout={LayoutView}
            component={OpinionForm}
            path={UrlCollection.OpinionForm}
          />

          {/* Land Type */}
          <RouteComponent
            exact
            layout={LayoutView}
            component={LandTypeManagement}
            path={UrlCollection.LandType}
          />

          {/* Land Type */}
          <RouteComponent
            exact
            layout={LayoutView}
            component={LandTypeDetailManagement}
            path={UrlCollection.LandTypeDetail}
          />

          {/* Table Layer Structure */}
          <RouteComponent
            exact
            layout={LayoutView}
            component={TableStructure}
            path={UrlCollection.TableStructure}
          />
          {/* Access Denied */}
          <RouteComponent
            exact
            layout={LayoutView}
            component={AccessDenied}
            path={UrlCollection.AccessDenied}
          />
           {/* my account */}
           <RouteComponent exact layout={LayoutView} component={MyAccount} path={UrlCollection.MyAccount} />
          <RouteComponent
            exact
            layout={LayoutView}
            component={DocumentManagement}
            path={UrlCollection.DocumentManagement}
          />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
