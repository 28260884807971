import React from "react";
import "./layers.scss";
import { Container, Draggable } from "react-smooth-dnd";
import ControlFunctionOpenlayerType from "../../../../components/open-layer/control-function-type/control-function-type";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as InitMapStore from "../../../../redux/store/init-map/init-map.store";
import ModalLayerSetting from "./modal-setting/modal-setting";
import ModalEditSettingLayer from "./modal-edit-layer/modal-edit-layer";
import ConfirmModalView from "../../../../components/confirm-modal/confirm-modal";
import OpenlayerCommand from "../../../../components/open-layer/control-function-type/control-function-type";

//--- Material Control
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Divider from "@material-ui/core/Divider";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import { Tooltip, IconButton, makeStyles, Typography } from "@material-ui/core";

//--- Material Icon
import AddCircleIcon from "@material-ui/icons/AddCircle";
import LayersIcon from "@material-ui/icons/Layers";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import EditIcon from "@material-ui/icons/Edit";
import TuneIcon from "@material-ui/icons/Tune";
import DeleteIcon from "@material-ui/icons/Delete";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import CloseIcon from "@material-ui/icons/Close";

class RenderLayerControlView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openConfirmModal: false,
      confirmModalTitle: "",
      confirmModalHandleAccept: null,
      dataSourcLayerSelected: "",
      listLayers: this.props.data,
      isOpenSettingGroupLayers: false,
      isCreateNewGroupLayers: false,
      isOpenSettingLayer: false,
      isCreateNewLayer: false,
      groupIndexSelected: null,
      layerIndexSelected: null,
      groupLayersName: "",
      idGroupLayerSelected: null,
      indexGroupSelected: null,
      hasShowWarningGroupLayerSetting: false,
      layerSettingViewType: 0, // view - 3, filter - 2, setting - 1, data source -0
    };
  }

  onSetDataSourceLayerSelected = (dataSource) =>
    this.setState({ dataSourcLayerSelected: dataSource });

  getChildPayloadGrouplayer = (index) => {
    return this.getLayerData.getLayerGroupByIndex(index);
  };

  getChildPayloadLayer = (indexLayer, indexGroupLayer) => {
    return this.getLayerData.getLayerByIndexGroupAndIndexLayer(
      indexGroupLayer,
      indexLayer
    );
  };

  onDropGroupLayer = (event) => {
    const ListGroupLayers = this.getLayerData.getListGroupLayers();
    ListGroupLayers.splice(event.removedIndex, 1);
    ListGroupLayers.splice(event.addedIndex, 0, event.payload);
    this.autoUpdateLayerData();
  };

  onDropLayer = (event, indexGrouplayer) => {
    const ListGroupLayer = this.getLayerData.getLayerGroupArrayLayerByIndex(
      indexGrouplayer
    );

    if (event.removedIndex != null) ListGroupLayer.splice(event.removedIndex, 1);
    if (event.addedIndex != null) ListGroupLayer.splice(event.addedIndex, 0, event.payload);

    if (event.removedIndex != null || event.addedIndex != null) this.autoUpdateLayerData();
  };

  onClickAddNewGroup = (event) => {
    event.stopPropagation();
    this.setState({
      groupLayersName: "",
      isOpenSettingGroupLayers: true,
      isCreateNewGroupLayers: true,
    });
  };

  onClickSettingGroupLayer = (event, indexGroup) => {
    event.stopPropagation();
    this.setState({
      isOpenSettingGroupLayers: true,
      isCreateNewGroupLayers: false,
      indexGroupSelected: indexGroup,
      groupLayersName: this.getLayerData.getLayerGroupByIndex(indexGroup)[
        "folder_label"
      ],
    });
  };

  onClickDeleteGrouplayer = () => {
    this.getLayerData
      .getListGroupLayers()
      .splice(this.state.indexGroupSelected, 1);
    this.setState({
      isOpenSettingGroupLayers: false,
    });
  };

  onClickOpenAddNewLayer = (event, groupLayerIndex) => {
    event.stopPropagation();
    this.setState({
      isOpenSettingLayer: true,
      isCreateNewLayer: true,
      groupIndexSelected: groupLayerIndex,
    });
  };

  onClickEditLayer = (event, groupLayerIndex, layerIndex) => {
    event.stopPropagation();
    this.setState({
      isOpenSettingLayer: true,
      isCreateNewLayer: false,
      groupIndexSelected: groupLayerIndex,
      layerIndexSelected: layerIndex,
    });
  };

  handleChangeSettingLayerType = (event, newValue) => {
    this.setState({
      layerSettingViewType: newValue,
    });
  };

  onClickCheckBoxLayer = (layerObject, indexLayer, indexGroup) => {
    const layerClicked = this.getLayerData.getLayerByIndexGroupAndIndexLayer(
      indexGroup,
      indexLayer
    );
    if (!layerClicked.is_check) layerClicked.is_check = true;
    else layerClicked.is_check = false;
    this.autoUpdateLayerData();
    this.toggleDisplayLayerAction(layerObject);
  };

  toggleDisplayLayerAction = (layerObject) => {
    this.props.selectToggleLayerFunction(
      ControlFunctionOpenlayerType.ToggleDisplayLayer,
      {
        layer: layerObject,
      }
    );
  };

  handleAddNewLayer = (newLayer) => {
    if (newLayer.is_check) this.toggleDisplayLayerAction(newLayer);
    this.getLayerData
      .getLayerGroupArrayLayerByIndex(this.state.groupIndexSelected)
      .push(newLayer);
  };

  handleClickAcceptSettingGrouplayer = () => {
    if (!this.state.groupLayersName.trim()) {
      if (!this.state.hasShowWarningGroupLayerSetting)
        this.setState({ hasShowWarningGroupLayerSetting: true });
      return;
    }

    if (this.state.isCreateNewGroupLayers) {
      this.getLayerData.getListGroupLayers().push({
        folder_label: this.state.groupLayersName,
        folder_name: this.state.groupLayersName,
        id: 0,
        layer_settings: [],
        level: 0,
        map_setting_id: this.getLayerData.getLayerData().map_id,
      });
    } else {
      this.getLayerData.getLayerGroupByIndex(this.state.indexGroupSelected)[
        "folder_label"
      ] = this.state.groupLayersName;
      this.getLayerData.getLayerGroupByIndex(this.state.indexGroupSelected)[
        "folder_name"
      ] = this.state.groupLayersName;
    }
    this.autoUpdateLayerData();
    this.setState({
      isOpenSettingGroupLayers: false,
      hasShowWarningGroupLayerSetting: false,
    });
  };

  autoUpdateLayerData = () => {
    this.props.UpdateLayers({ ...this.getLayerData.getLayerData() });
  };

  handleDeleteLayer = (event, layerSelectedIndex, groupLayerSelectedIndex) => {
    event.preventDefault();
    this.props.controlOpenlayer({
      type: OpenlayerCommand.ToggleDisplayLayer,
      option: {
        layer: this.getLayerData
          .getLayerGroupArrayLayerByIndex(groupLayerSelectedIndex)
          .splice(layerSelectedIndex, 1),
      },
    });
    this.autoUpdateLayerData();
  };

  getLayerData = {
    getLayerData: () => this.props.layerData,
    getListGroupLayers: () => this.props.layerData.layer_categories,
    getLayerGroupByIndex: (index) =>
      this.props.layerData.layer_categories[index],
    getLayerGroupArrayLayerByIndex: (index) =>
      this.props.layerData.layer_categories[index].layer_settings,
    getLayerByIndexGroupAndIndexLayer: (indexGroup, indexLayer) =>
      this.props.layerData.layer_categories[indexGroup].layer_settings[
        indexLayer
      ],
  };

  handleSaveEditLayer = (dataLayerEdited) => {
    if (
      dataLayerEdited.is_check !=
      this.getLayerData.getLayerGroupArrayLayerByIndex(
        this.state.groupIndexSelected
      )[this.state.layerIndexSelected].is_check
    )
      this.toggleDisplayLayerAction(dataLayerEdited);
    this.getLayerData.getLayerGroupArrayLayerByIndex(
      this.state.groupIndexSelected
    )[this.state.layerIndexSelected] = dataLayerEdited;
    this.autoUpdateLayerData();
  };

  openConfirmDeleteGroupLayer = () => {
    this.setState({
      openConfirmModal: true,
      confirmModalTitle: "Xóa nhóm layer",
      confirmModalHandleAccept: () => this.onClickDeleteGrouplayer(),
    });
  };

  openConfirmDeleteLayer = (event, indexLayer, indexGroup) => {
    this.setState({
      openConfirmModal: true,
      confirmModalTitle: "Xóa layer",
      confirmModalHandleAccept: () =>
        this.handleDeleteLayer(event, indexLayer, indexGroup),
    });
  };

  render() {
    return (
      <div className="left-menu-layer-control-container mt-2">
        <Accordion>
          <AccordionSummary
            aria-controls="additional-actions1-content"
            id="additional-actions1-header"
            className="position-relative base-map-header-summary"
          >
            <div
              className="row w-100 p-0 m-0"
              style={{ alignItems: "baseline" }}
            >
              <div className="col-8 p-0 m-0">
                <AccountTreeIcon className="icon-layer mr-2" />
                Layers
              </div>
              <div className="col-4 text-right p-0 m-0">
                <Tooltip title="Tạo nhóm layer mới">
                  <IconButton
                    onClick={(event) => this.onClickAddNewGroup(event)}
                  >
                    <AddCircleIcon fontSize="small" className="text-success" />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails className="d-block">
            <Divider />

            {this.props.layerData.haveData &&
              this.props.layerData.layer_categories.length === 0 && (
                <p className="text-center mt-3 mb-0">
                  Chưa có nhóm layer nào được tạo
                </p>
              )}

            <Container
              getChildPayload={this.getChildPayloadGrouplayer}
              onDrop={this.onDropGroupLayer}
              dragClass="drag-state-custom-ddb"
            >
              {this.props.layerData.haveData &&
                this.props.layerData.layer_categories.map(
                  (GroupLayers, indexGroup) => (
                    <Draggable key={indexGroup}>
                      <Accordion>
                        <AccordionSummary className="p-0">
                          <div className="container-fluid p-0 m-0">
                            <div className="row p-0 m-0 alignItems-baseline ">
                              <div
                                className="col-8 text-truncate position-relative pl-4"
                                title={GroupLayers.folder_label}
                              >
                                <LayersIcon
                                  className="position-absolute icon-layers-custom"
                                  color="primary"
                                />
                                <Tooltip title={GroupLayers.folder_label}>
                                  <span>{GroupLayers.folder_label}</span>
                                </Tooltip>
                              </div>
                              <div className="col-4 text-right p-0">
                                <Tooltip title="Thêm layer mới">
                                  <IconButton
                                    onClick={(event) =>
                                      this.onClickOpenAddNewLayer(
                                        event,
                                        indexGroup
                                      )
                                    }
                                  >
                                    <PlaylistAddIcon
                                      fontSize="small"
                                      className="text-success"
                                    />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Thiết đặt">
                                  <IconButton
                                    onClick={(event) =>
                                      this.onClickSettingGroupLayer(
                                        event,
                                        indexGroup
                                      )
                                    }
                                  >
                                    <TuneIcon
                                      fontSize="small"
                                      color="primary"
                                    />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails style={{ padding: "0 0 0 12px" }}>
                          <Container
                            getChildPayload={(event) =>
                              this.getChildPayloadLayer(event, indexGroup)
                            }
                            onDrop={(event) =>
                              this.onDropLayer(event, indexGroup)
                            }
                            dragClass="drag-state-custom-ddb"
                            groupName="Grouplayers"
                          >
                            {GroupLayers.layer_settings &&
                              GroupLayers.layer_settings.map(
                                (layer, indexLayer) => (
                                  <Draggable o key={indexLayer}>
                                    <div className="row alignItems-baseline no-gutters">
                                      <div className="col-1">
                                        <input
                                          type="checkbox"
                                          id={`checkbox-layer-index-${indexLayer}`}
                                          checked={layer.is_check}
                                          onChange={() => {}}
                                          onClick={() =>
                                            this.onClickCheckBoxLayer(
                                              layer,
                                              indexLayer,
                                              indexGroup
                                            )
                                          }
                                          className="mr-2"
                                        />
                                      </div>
                                      <div className="col-7">
                                        <Tooltip title={layer.name}>
                                          <label
                                            htmlFor={`checkbox-layer-index-${indexLayer}`}
                                            className="d-block text-truncate cursor-move"
                                          >
                                            {layer.name}
                                          </label>
                                        </Tooltip>
                                      </div>
                                      <div className="col-4 text-right">
                                        <Tooltip title="Chỉnh sửa">
                                          <IconButton
                                            onClick={(event) =>
                                              this.onClickEditLayer(
                                                event,
                                                indexGroup,
                                                indexLayer
                                              )
                                            }
                                          >
                                            <EditIcon
                                              color="primary"
                                              fontSize="small"
                                            />
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Xóa layer">
                                          <IconButton
                                            onClick={(event) =>
                                              this.openConfirmDeleteLayer(
                                                event,
                                                indexLayer,
                                                indexGroup
                                              )
                                            }
                                          >
                                            <DeleteIcon
                                              fontSize="small"
                                              className="text-danger"
                                            />
                                          </IconButton>
                                        </Tooltip>
                                      </div>
                                    </div>
                                  </Draggable>
                                )
                              )}
                            {GroupLayers.layer_settings &&
                              GroupLayers.layer_settings.length === 0 && (
                                <span>Chưa có layer nào</span>
                              )}
                          </Container>
                        </AccordionDetails>
                      </Accordion>
                    </Draggable>
                  )
                )}
            </Container>
          </AccordionDetails>
        </Accordion>

        {/* setting group layer */}

        <Dialog
          maxWidth="sm"
          fullWidth={true}
          onClose={() => this.setState({ isOpenSettingGroupLayers: false })}
          aria-labelledby="base-map-add-alert-dialog-title"
          aria-describedby="alert-dialog-description"
          id="dialog-create-base-map"
          open={this.state.isOpenSettingGroupLayers}
        >
          <DialogTitle
            id="base-map-add-alert-dialog-title"
            onClose={() => this.setState({ isOpenSettingGroupLayers: false })}
          >
            {this.state.isCreateNewGroupLayers ? "Thêm Nhóm mới" : "Thiết lập"}
          </DialogTitle>
          <DialogContent className="content-custom-container" dividers>
            <InputLabel className="text-dark">Tên nhóm</InputLabel>
            <TextField
              helperText={
                this.state.hasShowWarningGroupLayerSetting &&
                !this.state.groupLayersName.trim()
                  ? "Không được để trống"
                  : ""
              }
              error={
                this.state.hasShowWarningGroupLayerSetting &&
                !this.state.groupLayersName.trim()
              }
              value={this.state.groupLayersName}
              onChange={(event) =>
                this.setState({ groupLayersName: event.target.value })
              }
              fullWidth
              variant="outlined"
              size="small"
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => this.setState({ isOpenSettingGroupLayers: false })}
              color="error"
            >
              Đóng
            </Button>

            {!this.state.isCreateNewGroupLayers && (
              <Button
                variant="contained"
                onClick={() => this.openConfirmDeleteGroupLayer()}
                color="secondary"
              >
                <DeleteIcon className="mr-1" />
                Xóa nhóm
              </Button>
            )}

            <Button
              variant="contained"
              onClick={() => this.handleClickAcceptSettingGrouplayer()}
              color="primary"
            >
              Chấp nhận
            </Button>
          </DialogActions>
        </Dialog>

        {/* setting layer */}

        <Dialog
          maxWidth="lg"
          disableBackdropClick
          disableEscapeKeyDown
          fullWidth={true}
          id="dialog-create-base-map"
          open={this.state.isOpenSettingLayer}
          onClose={() => this.setState({ isOpenSettingLayer: false })}
        >
          <DialogTitle disableTypography id="base-map-add-alert-dialog-title">
            <Typography variant="h6">Tạo layer</Typography>
            <IconButton
              aria-label="close"
              onClick={() => this.setState({ isOpenSettingLayer: false })}
              style={{
                position: "absolute",
                top: 8,
                right: 8,
                color: "#9e9e9e",
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent
            className="content-custom-container"
            id="content-custom-container-setting-layer"
            dividers
          >
            {this.state.isCreateNewLayer
              ? this.state.isOpenSettingLayer && (
                  <ModalLayerSetting
                    planningId={this.props.planningId}
                    handleAddNewLayer={(newLayer) =>
                      this.handleAddNewLayer(newLayer)
                    }
                    closeModal={() =>
                      this.setState({ isOpenSettingLayer: false })
                    }
                  />
                )
              : this.state.isOpenSettingLayer && (
                  <ModalEditSettingLayer
                    setLayerData={(data) => this.handleSaveEditLayer(data)}
                    layerData={this.getLayerData.getLayerByIndexGroupAndIndexLayer(
                      this.state.groupIndexSelected,
                      this.state.layerIndexSelected
                    )}
                    closeModal={() =>
                      this.setState({ isOpenSettingLayer: false })
                    }
                  />
                )}
          </DialogContent>
        </Dialog>

        {/* Confirm modal */}
        <ConfirmModalView
          open={this.state.openConfirmModal}
          handleClose={() => this.setState({ openConfirmModal: false })}
          title={this.state.confirmModalTitle}
          handleAccept={this.state.confirmModalHandleAccept}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  layerData: state.initMap.layers,
  controlOpenlayer: state.openLayer.handleOutSideFunction,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      UpdateLayers: InitMapStore.UpdateLayer,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RenderLayerControlView);
