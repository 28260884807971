/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useForm } from "react-hook-form";

import {
  DialogActions,
  Button,
  TextField,
  DialogContent,
  DialogTitle,
  Dialog,
  Typography,
  IconButton,
  makeStyles,
  Checkbox,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FileInputComponent from "./../../../../components/file-input/file-input.view";
import * as configCommon from "./../../../../common/config";

import ShowNotification from "../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../utils/configuration";
import * as viVN from "../../../../language/vi-VN.json";
import * as appActions from "../../../../core/app.store";
import * as planningAction from "../../../../redux/store/planning/planning.store";
import * as communeAction from "../../../../redux/store/commune-management/commune.store";
import * as config from "../../../../utils/configuration";

import FileManagement from "../../../../components/file_management/file_management";

function EditRecordsManagement(props) {
  const classes = useStyles();

  const {
    isOpen,
    planningId,
    onClose,
    onSuccess,
    showLoading,
    cgisId,
    dbTypeId,
  } = props;

  const [planningModel, setPlanningModel] = useState(null);
  const [planningLookUpModel, setPlanningLookUpModel] = useState(null);
  const [planningStatusModel, setPlanningStatusModel] = useState([]);
  const [planningLevelModel, setPlanningLevelModel] = useState([]);
  const [planningTypeModel, setPlanningTypeModel] = useState([]);
  const [documentTypeModel, setDocumentTypeModel] = useState([]);
  const [approvalAgencyModel, setApprovalAgencyModel] = useState([]);
  const [lookupCommuneModel, setLookupCommuneModel] = useState([]);

  const [planningStatus, setPlanningStatus] = useState("");
  const [planningLevel, setPlanningLevel] = useState("");
  const [planningType, setPlanningType] = useState("");
  const [documentType, setDocumentType] = useState("");
  const [approvalAgency, setApprovalAgency] = useState("");
  const [lookupCommune, setLookupCommune] = useState(null);
  const [isDefault, setIsDefault] = React.useState(false);
  const [files, setFiles] = useState([]);
  const [filesTemp, setFilesTemp] = useState([]);
  const [isShow, setShow] = useState(false);
  const [tifFiles, setTifFiles] = useState([]);
  const [tifFilesTemp, setTifFilesTemp] = useState([]);
  const [isShowTif, setShowTif] = useState(false);
  const [zipFiles, setZipFiles] = useState([]);
  const [zipFilesTemp, setZipFilesTemp] = useState([]);
  const [isShowZip, setShowZip] = useState(false);
  const [tifName, setTifName] = useState(null);
  const [zipName, setZipName] = useState(null);

  const { register, handleSubmit, errors, setValue, clearErrors, setError } =
    useForm({
      mode: "all",
      reValidateMode: "onBlur",
    });

  useEffect(() => {
    onGetData();
  }, []);

  const onGetData = () => {
    showLoading(true);
    Promise.all([
      onGetDetailPlanning(),
      getLookupDistrict(),
      onGetLookUpPlanning(),
      onGetLookUpPlanningStatus(),
      onGetLookUpPlanningLevel(),
      onGetLookUpPlanningType(),
      onGetLookUpDocumentType(),
      getApprovalAgencyLevel(),
    ])
      .then((res) => {
        const [planning, lookupCommune] = res;
        setCommune(lookupCommune.content, planning.content);
        showLoading(false);
      })
      .catch((err) => {
        showLoading(false);
      });
  };

  const setCommune = (lookupCommune, planning) => {
    if (!lookupCommune || !planning) return;

    let district =
      planning.communeNames &&
      planning.communeNames.length > 0 &&
      lookupCommune.filter(
        (item) =>
          planning.communeNames.some((name) => name === item.name) === true
      );

    lookupCommune.length > 0 && setLookupCommune(district);

    if (district.length > 0) {
      setValue("lookupCommuneId", "lookupCommuneId");
      clearErrors("lookupCommuneId");
    } else {
      setValue("lookupCommuneId", "");
      setError("lookupCommuneId", { type: "required" });
    }
  };

  const onGetDetailPlanning = (id = planningId) => {
    return new Promise((resolve, reject) => {
      planningAction.GetDetailPlaning(id).then(
        (res) => {
          if (!res || !res.content) {
            reject(res);
          }

          setPlanningModel(res.content);
          setFiles(res.content.files ? [res.content.files] : []);
          setLookupCommune(res.content.communeIds || []);
          resolve(res);
        },
        (err) => {
          reject(err);
          ShowNotification(
            viVN.Errors[(err && err.errorType) || "UnableHandleException"],
            NotificationMessageType.Error
          );
        }
      );
    });
  };

  const onGetLookUpPlanning = () => {
    return new Promise((resolve, reject) => {
      planningAction.GetLookUpPlanning().then(
        (res) => {
          setPlanningLookUpModel(res && res.content);
          resolve(res);
        },
        (err) => {
          reject(err);
          ShowNotification(
            viVN.Errors[(err && err.errorType) || "UnableHandleException"],
            NotificationMessageType.Error
          );
        }
      );
    });
  };

  const onGetLookUpPlanningStatus = () => {
    return new Promise((resolve, reject) => {
      planningAction.GetLookUpPlanningStatus().then(
        (res) => {
          setPlanningStatusModel(res && res.content);
          resolve(res);
        },
        (err) => {
          reject(err);
          ShowNotification(
            viVN.Errors[(err && err.errorType) || "UnableHandleException"],
            NotificationMessageType.Error
          );
        }
      );
    });
  };

  const onGetLookUpPlanningLevel = () => {
    return new Promise((resolve, reject) => {
      planningAction.GetLookUpPlanningLevel().then(
        (res) => {
          setPlanningLevelModel(res && res.content);
          resolve(res);
        },
        (err) => {
          reject(err);
          ShowNotification(
            viVN.Errors[(err && err.errorType) || "UnableHandleException"],
            NotificationMessageType.Error
          );
        }
      );
    });
  };

  const onGetLookUpPlanningType = () => {
    return new Promise((resolve, reject) => {
      planningAction.GetLookUpPlanningType(dbTypeId).then(
        (res) => {
          setPlanningTypeModel(res && res.content);
          resolve(res);
        },
        (err) => {
          reject(err);
          ShowNotification(
            viVN.Errors[(err && err.errorType) || "UnableHandleException"],
            NotificationMessageType.Error
          );
        }
      );
    });
  };

  const getApprovalAgencyLevel = () => {
    return new Promise((resolve, reject) => {
      planningAction.ApprovalAgencyLevel().then(
        (res) => {
          setApprovalAgencyModel(res && res.content);
          resolve(res);
        },
        (err) => {
          reject(err);
          ShowNotification(
            viVN.Errors[(err && err.errorType) || "UnableHandleException"],
            NotificationMessageType.Error
          );
        }
      );
    });
  };

  const getLookupDistrict = () => {
    return new Promise((resolve, reject) => {
      communeAction.GetLookupCommune().then(
        (res) => {
          setLookupCommuneModel(res && res.content);
          resolve(res);
        },
        (err) => {
          reject(err);
          ShowNotification(
            viVN.Errors[(err && err.errorType) || "UnableHandleException"],
            NotificationMessageType.Error
          );
        }
      );
    });
  };

  const onGetLookUpDocumentType = () => {
    return new Promise((resolve, reject) => {
      planningAction.GetLookUpDocumentType().then(
        (res) => {
          setDocumentTypeModel(res && res.content);
          resolve(res);
        },
        (err) => {
          reject(err);
          ShowNotification(
            viVN.Errors[(err && err.errorType) || "UnableHandleException"],
            NotificationMessageType.Error
          );
        }
      );
    });
  };

  const onSubmit = (data) => {
    if (!data) {
      return;
    }

    showLoading(true);

    let params = {
      Id: planningModel.id || null,
      Name: data.planningName || null,
      PlanningCode: planningModel.planningCode || null,
      Photo: planningModel.photo || null,
      PlanningTypeId:
        (planningType && planningType.id) ||
        planningModel.planningTypeId ||
        null,
      DistrictId: planningModel.districtId || null,
      Order: 0,
      PlanningAgency: data.planningAgency || null,
      Investor: data.investor || null,
      PlanningStatusId:
        (planningStatus && planningStatus.id) ||
        planningModel.planningStatusId ||
        null,
      PlanningUnit: data.planningUnit || null,
      PlanningLevelId: 2,
      Population: 0,
      Acreage: 0,
      LandForConstruction: 0,
      Report: data.report || null,
      Note: data.note || null,
      DocumentTypeId: 1,
      ApprovalAgencyLevelId: 1,
      IsDefault: isDefault,
      PlanningDistrictIds:
        (lookupCommune &&
          lookupCommune.map((item) => {
            return item.id;
          })) ||
        null,
    };

    if (files && files.length > 0) {
      params = {
        ...params,
        DocumentUploadId: files && files[0].fileId,
      };
    }

    if (tifFiles && tifFiles.length > 0) {
      params = {
        ...params,
        tifFile: tifFiles && tifFiles[0].fileId,
      };
    }

    if (zipFiles && zipFiles.length > 0) {
      params = {
        ...params,
        shpFile: zipFiles && zipFiles[0].fileId,
      };
    }

    planningAction
      .UpdatePlanning(params)
      .then(
        (res) => {
          onSuccess();
        },
        (err) => {
          showLoading(false);
          err &&
            err.errorType &&
            ShowNotification(
              viVN.Errors[err.errorType],
              NotificationMessageType.Error
            );
        }
      )
      .catch((err) => {
        showLoading(false);
        ShowNotification(
          viVN.Errors[(err && err.errorType) || "UnableHandleException"],
          NotificationMessageType.Error
        );
      });
  };

  const onOpenSelectTifFile = () => {
    setShowTif(true);
    setTifFilesTemp(tifFiles);
  };

  const onCloseSelectTifFile = () => {
    setShowTif(false);
    setTifFiles(tifFilesTemp);
  };

  const onSaveSelectTifFile = () => {
    setShowTif(false);
    setTifName(tifFiles[0].fileName);
  };

  const onOpenSelectZipFile = () => {
    setShowZip(true);
    setZipFilesTemp(zipFiles);
  };

  const onCloseSelectZipFile = () => {
    setShowZip(false);
    setZipFiles(zipFilesTemp);
  };

  const onSaveSelectZipFile = () => {
    setShowZip(false);
    setZipName(zipFiles[0].fileName);
  };

  const onOpenSelectFile = () => {
    setShow(true);
    setFilesTemp(files);
  };

  const onCloseSelectFile = () => {
    setShow(false);
    setFiles(filesTemp);
  };

  const onSaveSelectFile = () => {
    setShow(false);
  };

  const handleChangeIsDefault = (event) => {
    event.persist();
    setIsDefault(event.target.checked);
  };
  return (
    <div>
      {isOpen && (
        <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth="md">
          <DialogTitle
            disableTypography
            id="alert-dialog-title"
            className="border-bottom"
          >
            <Typography variant="h6">Chỉnh sửa dự án quy hoạch</Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <form onSubmit={handleSubmit(onSubmit)}>
            {planningModel && (
              <DialogContent className="pt-4 pb-2">
                <div className="form-group row">
                  <div className="col-6">
                    <label className="text-dark">
                      Mã quy hoạch<span className="required"></span>
                    </label>

                    <TextField
                      fullWidth
                      type="text"
                      name="planningCode"
                      defaultValue={planningModel.planningCode}
                      disabled
                      variant="outlined"
                      size="small"
                    />
                  </div>
                  <div className="col-6">
                    <label className="text-dark">
                      Tên đồ án QH<span className="required"></span>
                    </label>

                    <TextField
                      fullWidth
                      inputRef={register({ required: true })}
                      type="text"
                      name="planningName"
                      defaultValue={planningModel.name}
                      error={
                        errors.planningName &&
                        errors.planningName.type === "required"
                      }
                      variant="outlined"
                      size="small"
                    />
                    {errors.planningName &&
                      errors.planningName.type === "required" && (
                        <span className="error">Trường này là bắt buộc</span>
                      )}
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-6">
                    <label className="text-dark">
                      Loại quy hoạch<span className="required"></span>
                    </label>

                    {planningTypeModel && planningTypeModel.length > 0 && (
                      <Autocomplete
                        options={planningTypeModel}
                        getOptionLabel={(option) => option.name}
                        value={
                          planningType ||
                          planningTypeModel.some(
                            (item) => item.id === planningModel.planningTypeId
                          )
                            ? planningTypeModel.find(
                                (item) =>
                                  item.id === planningModel.planningTypeId
                              )
                            : null
                        }
                        onChange={(event, newValue) => {
                          setPlanningType(newValue);
                        }}
                        disableClearable={true}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="planningTypeId"
                            inputRef={register({ required: true })}
                            error={
                              errors.planningTypeId &&
                              errors.planningTypeId.type === "required"
                            }
                            variant="outlined"
                            size="small"
                          />
                        )}
                      />
                    )}
                    {errors.planningTypeId &&
                      errors.planningTypeId.type === "required" && (
                        <span className="error">Trường này là bắt buộc</span>
                      )}
                  </div>
                  <div className="col-6">
                    <label className="text-dark">
                      Trạng thái<span className="required"></span>
                    </label>

                    {planningStatusModel && planningStatusModel.length > 0 && (
                      <Autocomplete
                        id="planningStatusId"
                        options={planningStatusModel}
                        getOptionLabel={(option) => option.name}
                        value={
                          planningStatus ||
                          planningStatusModel.some(
                            (item) => item.id === planningModel.planningStatusId
                          )
                            ? planningStatusModel.find(
                                (item) =>
                                  item.id === planningModel.planningStatusId
                              )
                            : null
                        }
                        onChange={(event, newValue) => {
                          setPlanningStatus(newValue);
                        }}
                        disableClearable={true}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="planningStatusId"
                            inputRef={register({ required: true })}
                            error={
                              errors.planningStatusId &&
                              errors.planningStatusId.type === "required"
                            }
                            variant="outlined"
                            size="small"
                          />
                        )}
                      />
                    )}
                    {errors.planningStatusId &&
                      errors.planningStatusId.type === "required" && (
                        <span className="error">Trường này là bắt buộc</span>
                      )}
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-6">
                    <label className="text-dark">
                      Cơ quan phê duyệt
                      <span className="required"></span>
                    </label>

                    <TextField
                      fullWidth
                      inputRef={register({ required: true })}
                      type="text"
                      name="planningAgency"
                      defaultValue={planningModel.planningAgency}
                      error={
                        errors.planningAgency &&
                        errors.planningAgency.type === "required"
                      }
                      variant="outlined"
                      size="small"
                    />
                    {errors.planningAgency &&
                      errors.planningAgency.type === "required" && (
                        <span className="error">Trường này là bắt buộc</span>
                      )}
                  </div>

                  <div className="col-6">
                    <label className="text-dark">
                      Đơn vị lập quy hoạch<span className="required"></span>
                    </label>

                    <TextField
                      fullWidth
                      inputRef={register({ required: true })}
                      type="text"
                      name="planningUnit"
                      defaultValue={planningModel.planningUnit}
                      error={
                        errors.planningUnit &&
                        errors.planningUnit.type === "required"
                      }
                      variant="outlined"
                      size="small"
                    />
                    {errors.planningUnit &&
                      errors.planningUnit.type === "required" && (
                        <span className="error">Trường này là bắt buộc</span>
                      )}
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-6">
                    <label className="text-dark">
                      Chủ đầu tư<span className="required"></span>
                    </label>

                    <TextField
                      fullWidth
                      inputRef={register({ required: true })}
                      type="text"
                      name="investor"
                      defaultValue={planningModel.investor}
                      error={
                        errors.investor && errors.investor.type === "required"
                      }
                      variant="outlined"
                      size="small"
                    />
                    {errors.investor && errors.investor.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                  </div>
                  <div className="col-6">
                    <label className="text-dark">Quyết định phê duyệt</label>
                    <TextField
                      fullWidth
                      type="text"
                      name="report"
                      defaultValue={planningModel.report}
                      variant="outlined"
                      size="small"
                      inputRef={register}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-6">
                    <label className="text-dark">
                      Xã / phường<span className="required"></span>
                    </label>
                    {lookupCommuneModel && lookupCommuneModel.length > 0 && (
                      <Autocomplete
                        multiple
                        options={lookupCommuneModel}
                        getOptionLabel={(option) => option.name}
                        defaultValue={lookupCommuneModel.filter((item) => {
                          if (
                            planningModel &&
                            planningModel.communeNames &&
                            planningModel.communeNames.length > 0 &&
                            planningModel.communeNames.some(
                              (name) => name === item.name
                            )
                          ) {
                            return item;
                          }
                        })}
                        onChange={(event, newValue) => {
                          setLookupCommune(newValue);
                          if (newValue.length > 0) {
                            setValue("lookupCommuneId", "11");
                            clearErrors("lookupCommuneId");
                          } else {
                            setValue("lookupCommuneId", "");
                            setError("lookupCommuneId", { type: "required" });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={
                              errors.lookupCommuneId &&
                              errors.lookupCommuneId.type === "required"
                            }
                            variant="outlined"
                            size="small"
                          />
                        )}
                      />
                    )}
                    <TextField
                      name="lookupCommuneId"
                      hidden
                      inputRef={register({ required: true })}
                    />
                    {errors.lookupCommuneId &&
                      errors.lookupCommuneId.type === "required" && (
                        <span className="error">Trường này là bắt buộc</span>
                      )}
                  </div>
                  {parseInt(dbTypeId) !== 1 ? (
                    <div className="col-6 d-flex align-items-center">
                      <div>
                      <label className="text-dark">Hiện mặc định</label>
                      <Checkbox
                        checked={isDefault}
                        onChange={handleChangeIsDefault}
                        color="primary"
                        inputProps={{ "aria-label": "secondary checkbox" }}
                        className="p-0 mt-0 ml-4"
                      />
                      </div>
                      
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="form-group row">
                  <div className="col-6"></div>
                </div>

                <div className="form-group row">
                  <div className="col-6">
                    <label className="text-dark">File Tif</label>
                    <TextField
                      fullWidth
                      type="text"
                      name="tifFileName"
                      value={tifName || planningModel.tifName}
                      placeholder="Hãy tải file tif lên."
                      disabled
                      variant="outlined"
                      size="small"
                    />
                    <div className="mt-2">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={onOpenSelectTifFile}
                      >
                        Chọn file Tif
                      </Button>
                    </div>
                  </div>
                  <div className="col-6">
                    <label className="text-dark">File Zip</label>
                    <TextField
                      fullWidth
                      type="text"
                      name="zipFileName"
                      value={zipName || planningModel.shpName}
                      placeholder="Hãy tải file zip lên."
                      disabled
                      variant="outlined"
                      size="small"
                    />
                    <div className="mt-2">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={onOpenSelectZipFile}
                      >
                        Chọn file zip
                      </Button>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="text-dark">Ghi chú</label>

                  <textarea
                    name="note"
                    rows="5"
                    className="form-control"
                    defaultValue={planningModel.note}
                    ref={register}
                  ></textarea>
                </div>

                <div className="form-group">
                  <div className="row justify-content-between align-items-end">
                    <div className="col-6 col-md-4">
                      <label className="text-dark">
                        Ảnh<span className="required"></span>
                      </label>
                      {!isShow &&
                        files &&
                        files.length > 0 &&
                        files.map((item) => (
                          <div key={item.fileName} style={{ width: "150px" }}>
                            <img
                              src={config.APIUrlDefault + item.filePreview}
                              alt={item.fileName}
                              className="img-fluid mb-2"
                              style={{
                                width: "auto",
                                height: "auto",
                                maxWidth: "100%",
                                maxHeight: "100%",
                              }}
                            />
                          </div>
                        ))}
                      <div>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={onOpenSelectFile}
                        >
                          Chọn file
                        </Button>
                        <TextField
                          inputRef={register({ required: true })}
                          type="hidden"
                          name="image"
                          value={
                            (files && files.length > 0 && files[0].fileName) ||
                            ""
                          }
                        />
                        {errors.image && errors.image.type === "required" && (
                          <p className="error">Trường này là bắt buộc</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </DialogContent>
            )}

            <DialogActions className="border-top">
              <Button
                type="button"
                onClick={onClose}
                variant="contained"
                startIcon={<CloseIcon />}
              >
                Hủy
              </Button>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
              >
                Lưu
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      )}

      {isShow && (
        <Dialog
          onClose={onCloseSelectFile}
          open={isShow}
          fullWidth={true}
          maxWidth="md"
          className="dialog-preview-form"
        >
          <DialogTitle disableTypography>
            <Typography variant="h6">Quản lý file</Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onCloseSelectFile}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <FileManagement
              files={files}
              setFiles={setFiles}
              acceptedFiles={["jpeg", "png", "jpg", "gif"]}
            />
          </DialogContent>

          <DialogActions>
            <Button
              type="button"
              onClick={onCloseSelectFile}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            {files && files.length > 0 && (
              <Button
                type="button"
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={onSaveSelectFile}
              >
                Lưu
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}

      {isShowTif && (
        <Dialog
          onClose={onCloseSelectTifFile}
          open={isShowTif}
          fullWidth={true}
          maxWidth="md"
          className="dialog-preview-form"
        >
          <DialogTitle disableTypography>
            <Typography variant="h6">Quản lý file</Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onCloseSelectTifFile}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <FileManagement
              files={tifFiles}
              setFiles={setTifFiles}
              acceptedFiles={["tif"]}
              isShowLeft={false}
              isShowAddFolder={false}
              isShowDownload={true}
              typeFile={"TIF"}
              cgisId={cgisId}
            />
          </DialogContent>

          <DialogActions>
            <Button
              type="button"
              onClick={onCloseSelectTifFile}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            {tifFiles && tifFiles.length > 0 && tifFiles !== tifFilesTemp && (
              <Button
                type="button"
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={onSaveSelectTifFile}
              >
                Lưu
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}

      {isShowZip && (
        <Dialog
          onClose={onCloseSelectZipFile}
          open={isShowZip}
          fullWidth={true}
          maxWidth="md"
          className="dialog-preview-form"
        >
          <DialogTitle disableTypography>
            <Typography variant="h6">Quản lý file</Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onCloseSelectZipFile}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <FileManagement
              files={zipFiles}
              setFiles={setZipFiles}
              acceptedFiles={["zip"]}
              isShowLeft={false}
              isShowAddFolder={false}
              isShowDownload={true}
              typeFile={"ZIP"}
              cgisId={cgisId}
            />
          </DialogContent>

          <DialogActions>
            <Button
              type="button"
              onClick={onCloseSelectZipFile}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            {zipFiles && zipFiles.length > 0 && zipFiles !== zipFilesTemp && (
              <Button
                type="button"
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={onSaveSelectZipFile}
              >
                Lưu
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditRecordsManagement);
