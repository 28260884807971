/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import dateformat from "dateformat";

//--- Material Control
import {
  makeStyles,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Paper,
  TableContainer,
  Table,
  TableBody,
  Tooltip,
  IconButton,
  Collapse,
  Button,
} from "@material-ui/core";

//--- Material Icon
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";



const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  tableContainer: {
    maxHeight: window.outerHeight - 365,
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const headCells = [
  { id: "content", hideSortIcon: true, label: "Nội dung bình luận" },
  { id: "createdBy", hideSortIcon: false, label: "Người bình luận" },
  { id: "createdDate", hideSortIcon: false, label: "Ngày bình luận" },
  { id: "active", hideSortIcon: false, label: "Trả lời" },
  { id: "active", hideSortIcon: false, label: "Duyệt bài" },
  { id: "actions", hideSortIcon: true, label: "" },
];

function EnhancedTableHead(props) {

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            // sortDirection={orderBy === headCell.id ? order : false}
            className="pt-3 pb-3 text-nowrap"
          >
            <TableSortLabel
              // direction={orderBy === headCell.id ? order : 'asc'}
              hideSortIcon={headCell.hideSortIcon ? true : false}
            >
              {headCell.label}
             
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  // rowCount: PropTypes.number.isRequired,
};

export default function ListConsultTheCommunity(props) {
  const {
    dataList,
    editAction
  } = props;

  const classes = useStyles();



  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer className={classes.tableContainer}>
          <Table className={classes.table} size="small" stickyHeader>
            <EnhancedTableHead
              classes={classes}
              // rowCount={dataList.length}
            />
            <TableBody>
              {dataList && dataList.length > 0 ? dataList.map((item) => (
                <TableRow hover key={item.id}>
                 
                  <TableCell>
                    {item.content}
                  </TableCell>
                  <TableCell>
                    {item.created_by}
                  </TableCell>
                  <TableCell>
                    {item.created_date}
                  </TableCell>
                  <TableCell style={{ minWidth: 150 }}>
                          {" "}
                          <Button
                            variant="contained"
                            className="bg-success text-white"
                            // onClick={() => {
                            //   setReplyDialog(true);
                            //   setReflectPostId(row.id);
                            // }}
                          >
                            Trả lời
                          </Button>
                        </TableCell>
                  <TableCell style={{ minWidth: 150 }}>
                          {" "}
                          <Button
                            variant="contained"
                            className="bg-success text-white"
                            // onClick={() => {
                            //   setReplyDialog(true);
                            //   setReflectPostId(row.id);
                            // }}
                          >
                            Duyệt
                          </Button>
                        </TableCell>
                  <TableCell align="right" className="text-nowrap">
                    <Tooltip title="Sửa">
                      <IconButton
                        aria-label="edit"
                        onClick={() => editAction(item)}
                      >
                        <EditIcon className="text-primary" />
                      </IconButton>
                    </Tooltip>

                    {/* <Tooltip title="Xóa">
                      <IconButton
                        aria-label="delete"
                        // onClick={() =>
                        //   deleteAction(dataList.id, dataList.formTemplateId)
                        // }
                      >
                        <DeleteIcon className="text-danger" />
                      </IconButton>
                    </Tooltip> */}
                  </TableCell>
                </TableRow>
              )) : (
                <TableRow hover>
                  <TableCell colSpan={9} align="center">
                    Không có dữ liệu
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
