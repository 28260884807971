/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

//--- Material Control
import IconButton from "@material-ui/core/IconButton";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AddBoxIcon from "@material-ui/icons/AddBox";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import { useForm } from "react-hook-form";
import ShowNotification from "../../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../../utils/configuration";
import * as viVN from "../../../../../language/vi-VN.json";
import * as planningAction from "../../../../../redux/store/planning/planning.store";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import AddIcon from "@material-ui/icons/Add";

import {
  DialogTitle,
  DialogActions,
  Typography,
  TextField,
  Dialog,
  Button,
  DialogContent,
} from "@material-ui/core";
import * as appActions from "../../../../../core/app.store";
import './planning-related.scss';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    },
    inputs: {
        height: "0.3em",
    },
}));

function PlanningRelatedDialog(props) {
  const {
    openPlanningRelatedDialog,
    handleClosePlanningRelatedDialog,
    planningId,
    showLoading,
    onSuccess,
    planningName,
  } = props;

  const [projectList, setProjectList] = useState([]);

  const [
    planningRelationshipTypeModel,
    setPlanningRelationshipTypeModel,
  ] = useState([]);

  const [
    planningAPlanningApprovedModel,
    setPlanningAPlanningApprovedModel,
  ] = useState([]);

  const [planningBoundariesModel, setPlanningBoundariesModel] = useState([]);
    const [layers, setLayers] = useState([]);
  const { register, handleSubmit, errors } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  const classes = useStyles();

  useEffect(() => {
    onGetData();
  }, [planningId]);

  const onGetData = () => {
    showLoading(true);
    Promise.all([
      getPlanningRelationshipTypeById(planningId),
      getAllPlanningRelationshipType(),
      getPlanningApprovedById(planningId),
      getAllPlanningBoundaries(),
    ])
      .then((res) => {
        const [
          planningRelationshipTypeByIdModels,
          planningRelationshipType,
          planningAPlanningApprovedModel,
          planningBoundariesModel,
        ] = res;
        planningRelationshipTypeByIdModels &&
          planningRelationshipTypeByIdModels.content &&
          planningRelationshipTypeByIdModels.content.length > 0 &&
              setProjectList(planningRelationshipTypeByIdModels.content);
        planningRelationshipType &&
          planningRelationshipType.content &&
          setPlanningRelationshipTypeModel(planningRelationshipType.content);
        planningAPlanningApprovedModel &&
          planningAPlanningApprovedModel.content &&
          setPlanningAPlanningApprovedModel(
            planningAPlanningApprovedModel.content
          );
        planningBoundariesModel &&
          planningBoundariesModel.content &&
          planningBoundariesModel.content.length > 0 &&
              setPlanningBoundariesModel(planningBoundariesModel.content);

          if (planningRelationshipTypeByIdModels &&
              planningRelationshipTypeByIdModels.content) {
              for (var i = 0; i < planningRelationshipTypeByIdModels.content.length; i++) {
                  setLayers([...layers, planningRelationshipTypeByIdModels.content[i].layers]);
              }
          }
        showLoading(false);
      })
      .catch((err) => {
        showLoading(false);
      });
  };

  //Api nhóm
  const getAllPlanningRelationshipType = () => {
    return new Promise((resolve, reject) => {
      planningAction.PlanningRelationshipType().then(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
          err &&
            err.errorType &&
            ShowNotification(
              viVN.Errors[err.errorType],
              NotificationMessageType.Error
            );
        }
      );
    });
  };

  // API Get all boundaries
  const getAllPlanningBoundaries = () => {
    return new Promise((resolve, reject) => {
      planningAction.GetAllBoundaries().then(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
          err &&
            err.errorType &&
            ShowNotification(
              viVN.Errors[err.errorType],
              NotificationMessageType.Error
            );
        }
      );
    });
  };

  //Api lấy ra thằng theo planning id
  const getPlanningRelationshipTypeById = (planningId) => {
    return new Promise((resolve, reject) => {
      planningAction.PlanningRelationshipTypeById(planningId).then(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
          err &&
            err.errorType &&
            ShowNotification(
              viVN.Errors[err.errorType],
              NotificationMessageType.Error
            );
        }
      );
    });
  };

  //Api lấy ra thằng theo planning id
  const getPlanningApprovedById = (planningId) => {
    return new Promise((resolve, reject) => {
      planningAction.PlanningApprovedById(planningId).then(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
          err &&
            err.errorType &&
            ShowNotification(
              viVN.Errors[err.errorType],
              NotificationMessageType.Error
            );
        }
      );
    });
    };

    const getLookupLayerByPlanningId = (planningId, index, list) => {
        return new Promise((resolve, reject) => {
            planningAction.GetLookupLayerByPlanningId(planningId).then(
                (res) => {
                    list[index]["layers"] = res.content;
                    setProjectList(list);
                },
                (err) => {
                    reject(err);
                    err &&
                        err.errorType &&
                        ShowNotification(
                            viVN.Errors[err.errorType],
                            NotificationMessageType.Error
                        );
                }
            );
        });
    };

  const checkUniqueProjectList = (projectList) => {
    let objectArr = [...projectList];
    let arr = [];
    objectArr.filter((item) => {
        arr.push({"planningRelationid": item.planningRealationShipId, "layerId": item.layerId });
    });
    let arr2 = [];
    let findDuplicates = (arr) =>
      arr.filter((item, index) => arr.indexOf(item) != index);
    arr2 = [...new Set(findDuplicates(arr))];
    return arr2;
  };

  const handleOnchange = (event, newValue, index) => {
    if (newValue) {
      const list = [...projectList];
      list[index]["planningRealationShipId"] = newValue.id;
        list[index]["planningName"] = newValue.name;
        getLookupLayerByPlanningId(newValue.id, index,list);
      //setProjectList(list);
    }
  };

  const handleOnchangeGroup = (event, newValue, index) => {
    if (newValue) {
      const list = [...projectList];
      list[index]["planningRelationShipTypeId"] = newValue.id;
      list[index]["planningTypeName"] = newValue.name;
      setProjectList(list);
    }
    };

    const handleOnchangeLayer = (event, newValue, index) => {
        if (newValue) {
            const list = [...projectList];
            list[index]["layerId"] = newValue.id;
            list[index]["layerName"] = newValue.name;
            setProjectList(list);
        }
    };

    const handleOnchangeRelationName = (event, newValue, index) => {
        if (newValue) {
            const list = [...projectList];
            list[index]["relationName"] = newValue;
            setProjectList(list);
        }
    };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...projectList];
    list.splice(index, 1);
    setProjectList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setProjectList([
      ...projectList,
      {
        id: 0,
        planningRelationShipTypeId: 0,
        planningId: planningId,
        planningRealationShipId: 0,
        planningTypeName: "",
          planningName: "",
          layerId: 0,
        layerName:"",
      },
    ]);
  };

  const onSubmit = (data) => {
    if (!data) return;
    if (checkUniqueProjectList(projectList).length > 0) {
      ShowNotification(
        viVN.Errors.ExitPlanningRelationship,
        NotificationMessageType.Error
      );
    } else {
      showLoading(true);
      planningAction
        .PutPlanningReletionship(projectList, planningId)
        .then((result) => {
          if (result && result.content === true) {
            showLoading(false);
            onSuccess();
            ShowNotification(
              viVN.Success.PutPlanningReletionship,
              NotificationMessageType.Success
            );
          } else {
            showLoading(false);
            ShowNotification(
              viVN.Errors.PutPlanningReletionship,
              NotificationMessageType.Error
            );
          }
        })
        .catch((err) => {
          showLoading(false);
          onSuccess();
          ShowNotification(
            viVN.Errors.UserAlreadyExits,
            NotificationMessageType.Error
          );
        });
    }
  };

  return (
    <div>
      <Dialog
        open={openPlanningRelatedDialog}
        onClose={handleClosePlanningRelatedDialog}
        fullWidth={true}
        maxWidth="xl"
      >
        <DialogTitle disableTypography>
          <Typography variant="h6">{planningName}</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClosePlanningRelatedDialog}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent className="pt-4 pb-4" dividers>
            {projectList && projectList.length > 0 ? (
              <div>
                {projectList.map((item, index) => {
                    return (
                    <div className="row mb-2" key={ index}>
                      <div className="col-3" id={"index" + index}>
                        {planningAPlanningApprovedModel &&
                          planningAPlanningApprovedModel.length > 0 && (
                            <Autocomplete
                              id={`combo-box-demo-${item.id}`}
                              options={planningAPlanningApprovedModel}
                              getOptionLabel={(option) => option.name}
                              fullWidth
                              onChange={(event, newValue) =>
                                handleOnchange(event, newValue, index)
                              }
                                        value={item.planningRealationShipId ? {
                                            id: item.planningRealationShipId,
                                            name: item.planningName,
                                        } : null}
                              disableClearable={true}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Đồ án quy hoạch liên quan"
                                  name={`planningRelatedId${index}`}
                                  inputRef={register({ required: true })}
                                  size="small"
                                  variant="outlined"
                                  error={
                                    errors[`planningRelatedId${index}`] &&
                                    errors[`planningRelatedId${index}`].type ===
                                    "required"
                                  }
                                />
                              )}
                            />
                          )}
                      </div>
                      <div className="col-2">
                        {planningRelationshipTypeModel &&
                          planningRelationshipTypeModel.length > 0 && (
                            <Autocomplete
                              id={`combo-box-group-${item.id}`}
                              options={planningRelationshipTypeModel}
                              label="Nhóm"
                              getOptionLabel={(option) => option.name}
                              fullWidth
                              onChange={(event, newValue) =>
                                handleOnchangeGroup(event, newValue, index)
                              }
                                        value={item.planningRelationShipTypeId ? {
                                            id: item.planningRelationShipTypeId,
                                            name: item.planningTypeName,
                                        } : null}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Nhóm"
                                  name={`planningReletionshipType${index}`}
                                  size="small"
                                  variant="outlined"
                                  inputRef={register({ required: true })}
                                  error={
                                    errors[
                                    `planningReletionshipType${index}`
                                    ] &&
                                    errors[`planningReletionshipType${index}`]
                                      .type === "required"
                                  }
                                />
                              )}
                            />
                          )}
                          </div>
                            <div className="col-3">
                                {item.layers &&
                                    item.layers.length > 0 && (
                                    
                                        <Autocomplete
                                            id={`combo-box-layer-${item.id}`}
                                        options={item.layers}
                                            label="Lớp"
                                            getOptionLabel={(option) => option.name}
                                            fullWidth
                                            onChange={(event, newValue) =>
                                                handleOnchangeLayer(event, newValue, index)
                                            }
                                            value={item.layerId ? {
                                                id: item.layerId,
                                                name: item.layerName,
                                            } : null}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Lớp"
                                                    name={`layer${index}`}
                                                    size="small"
                                                    variant="outlined"
                                                    inputRef={register({ required: true })}
                                                    error={
                                                        errors[
                                                        `layer${index}`
                                                        ] &&
                                                        errors[`layer${index}`]
                                                            .type === "required"
                                                    }
                                                />
                                            )}
                                        />
                                    )}
                            </div>
                            <div className="col-3">
                                <TextField
                                    label="Tên"
                                    name={`relationName${index}`}
                                    error={
                                        errors[
                                        `relationName${index}`
                                        ] &&
                                        errors[`relationName${index}`]
                                            .type === "required"
                                    }
                                    defaultValue={item.relationName? item.relationName : null}
                                    fullWidth
                                    type="text"
                                    className="form-control"
                                    inputProps={{ maxLength: 150, className: classes.inputs }}
                                    inputRef={register({ required: true, maxLength: 150 })}
                                    variant="outlined"
                                    onChange={(e) =>
                                        handleOnchangeRelationName(e, e.target.value, index)
                                    }
                                />
                            </div>
                      <div className="col-1">
                        {
                          <IconButton
                            color="secondary"
                            onClick={() => {
                              handleRemoveClick(index);
                            }}
                          >
                            <IndeterminateCheckBoxIcon />
                          </IconButton>
                        }
                      </div>
                    </div>
                  );
                })}

                <div className="row align-items-center">
                  <div className="col-11">
                    <a
                      className="button-add-click"
                      onClick={handleAddClick}
                    >
                      <AddIcon className="mt-n1" />
                      {"Thêm quy hoạch"}
                    </a>
                  </div>
                </div>
              </div>
            ) : (
                <div>
                  <div className="text-danger text-center">
                    <HighlightOffIcon className="mt-n1 mr-1" />
                    {"Không có bản ghi nào"}
                  </div>
                </div>
              )}
          </DialogContent>

          <DialogActions>
            <Button
              onClick={handleAddClick}
              variant="contained"
              startIcon={<AddBoxIcon />}
              className="bg-success text-white"
              hidden={projectList && projectList.length > 0}
            >
              Thêm quy hoạch
            </Button>
            <Button
              onClick={handleClosePlanningRelatedDialog}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              startIcon={<SaveIcon />}
            >
              Lưu
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
    },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlanningRelatedDialog);
